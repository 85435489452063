<template lang="pug">
	div(class="form-container")
		div(v-if="!loading" class="container")
			div(v-if="typeof teaser == 'string' && teaser.length > 0" class="form-teaser" v-html="teaser")
			div(v-if="errors.length > 0" class="row" id="error-container")
				div(class="col s12")
					div(v-for="(e, index) in errors" class="form-error") {{ e }}
			div(v-if="Array.isArray(form_config) &&  form_config.length > 0 && formState != 'submitted'" v-for="(field, index) in form_config" :key="field.key" class="row")
				div(v-if="field.type == 'text'" class="input-field col s12")
					input(type="text" v-bind:id="field.key" v-bind:name="field.key" v-bind:placeholder="field.label" :disabled="formState == 'validated'" v-model="formData[field.key]")
					label(class="active") {{ field.label }}
				div(v-else-if="field.type == 'textarea'" class="input-field col s12")
					textarea(v-bind:id="field.key" class="materialize-textarea" v-bind:name="field.key" v-bind:placeholder="field.label" :disabled="formState == 'validated'" v-model="formData[field.key]")
					label(class="active") {{ field.label }}
				div(v-else-if="field.type == 'email'" class="input-field col s12")
					input(type="email" v-bind:id="field.key" v-bind:name="field.key" v-bind:placeholder="field.label" :disabled="formState == 'validated'" v-model="formData[field.key]")
					label(class="active") {{ field.label }}
				div(v-else-if="field.type == 'number'" class="input-field col s12")
					input(type="number" v-bind:id="field.key" v-bind:name="field.key" v-bind:placeholder="field.label" :disabled="formState == 'validated'" v-model="formData[field.key]")
					label(class="active") {{ field.label }}
				div(v-else class="col s12") {{ field }}
			div(v-if="formState != 'submitted'" class="row")
				div(class="col s12 button-wrapper")
					button(class="btn z-depth-0 form-submit-button" @click="submitForm()") {{ formLabel }}
			div(v-if="formState == 'submitted'" class="row")
				div(class="col s12")
					div(class="form-submitted" v-html="form_submitted_message")
		dgbrt-loading(v-else)
</template>

<style scoped>
.form-container {
	background-color: #e5e5e5;
	color: #233642;
	padding: 50px;
}

.form-teaser {
	text-align: center;
	padding-bottom: 50px;
}

.form-teaser:after {
	content: '';
	width: 60px;
	height: 3px;
	background: #233642;
	display: block;
	margin: 20px auto;
}

.form-container label,
.form-container input {
	text-transform: uppercase;
	color: #233642;
}

.form-container label {
	padding-left: 16px;
	font-weight: bold;
}

.form-container input {
	border-bottom: 2px solid #233642;
}

input:not([type]):focus:not([readonly]),
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=url]:not(.browser-default):focus:not([readonly]),
input[type=time]:not(.browser-default):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=datetime]:not(.browser-default):focus:not([readonly]),
input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
input[type=tel]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=search]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #1d6765;
    box-shadow: 0 1px 0 0 #1d6765;
}

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
    border-bottom: 1px solid #1d6765;
    box-shadow: 0 1px 0 0 #1d6765;
}

input:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.button-wrapper {
	text-align: center;
}

.form-submit-button {
	background-color: #e1783e;
	border-radius: 0px;
	padding-left: 30px;
	padding-right: 30px;
	width: 100%;
	max-width: 600px;
	font-size: 2em;
	padding: 10px;
	height: auto;
	font-weight: bold;
}

.form-error {
	color: red;
	text-transform: uppercase;
	font-weight: bold;
}

.form-submitted {
	text-align: center;
	text-transform: uppercase;
	color: #1d6765;
	font-size: 2em;
	font-weight: bold;
}


@media screen and (max-width: 650px) {
	.form-container {
		padding: 20px;
	}

	.form-container .container {
		width: 100%;
	}
}
</style>

<script>
import axios from 'axios';
import Loading from '@/components/Loading';

export default {
	props: [
		'teaser',
		'endpoint',
		'source',
		'form_config',
		'submit_label',
		'form_submitted_message'
	],
	components: {
		'dgbrt-loading': Loading,
	},
	data: function() {
		return {
			formState: 'initial',
			loading: false,
			errors: [],
			formData: {}
		}
	},
	computed: {
		formLabel() {
			return this.submit_label ? this.submit_label : 'Weiter';
		}
	},
	methods: {
		validateForm() {
			this.errors = [];

			for(let i = 0; i < this.form_config.length; i++) {
				let field = this.form_config[i];
				let required = typeof field['required'] != 'boolean' || !field['required'] ? false : true;
				if(required) {
					if(typeof this.formData[field['key']] != 'string' || this.formData[field['key']].length < 1) {
						this.errors.push('Das Feld '+field['label']+' muss ausgefüllt werden');
					}
				}
			}

			return this.errors.length > 0 ? false : true;
		},
		submitForm() {
			this.errors = [];
			switch(this.formState) {
				case 'initial':
					if(this.validateForm()) {
						this.formState = 'validated';
						
						this.formData['source'] = this.source;
						var vm = this;
						this.loading = true;

						axios.post(this.endpoint, this.formData)
							// eslint-disable-next-line no-unused-vars
							.then(function (response) {
								vm.formState = 'submitted';
								vm.loading = false;
							})
							.catch(function (error) {
								vm.errors.push(error);
								vm.formState = 'initial';
								vm.loading = false;
								vm.$nextTick(function() {
									window['document'].querySelector('#error-container').scrollIntoView({ block: 'start',  behavior: 'smooth' });
								});
							});

					} else {
						this.$nextTick(function() {
							window['document'].querySelector('#error-container').scrollIntoView({ block: 'start',  behavior: 'smooth' });
						});
					}
				break;
				default:
					this.formState = 'initial';
				break;
			}
		}
	}
}
</script>