<template lang="pug">
	div(class="simplebox")
		div(class="simplebox-content" v-html="content")
</template>

<style scoped>
.simplebox {
	background-color: rgba(255, 255, 255, 0.7);
	color: #233642;
	padding: 3em 4em;
	font-size: 1.2em;
}

.simplebox .simplebox-content:after {
	content: '';
	width: 60px;
	height: 5px;
	background: #233642;
	display: block;
	margin: 20px auto;
}

.simplebox .simplebox-content {
	text-align: center;
}
</style>

<script>
export default {
	props: ['content']
}
</script>