<template lang="pug">
	div(class="diy")
		div(class="row")
			div(class="col s12")
				simplebox(content="Die Dagobert Ticketing Plattform inklusive aller Funktionen wie Print@Home und Hardtickets, Saalplänen, Whitelabel Shop und Ticket Personalisierung.")
		
		div(class="row")
			div(class="col s12")
				separator(text="Pricing")
		
		div(class="row box-row")
			div(class="col s12")
				div(class="diybox s-mb")
					div(class="diybox-content with-after")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--06.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h2 0,60€ pro Ticket
		
		div(class="row flex box-row")
			div(class="col l4 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--07.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h4 Faire Abrechnung
						div(class="row row-no-mb")
							div(class="col m12 s12")
								p Es wird nur das gezahlt, was verkauft wird.
			div(class="col l4 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--08.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h4 Ticketeinnahmen
						div(class="row row-no-mb")
							div(class="col m12 s12")
								p Die Ticketeinnahmen ﬂießen direkt an den Veranstalter
			div(class="col l4 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--09.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h4 Zusatzgebühren
						div(class="row row-no-mb")
							div(class="col m12 s12")
								p Versandkosten &amp; Gebühren für Tickets selbst festlegen und zu 100% behalten.

		div(class="row")
			div(class="col s12")
				separator(text="Ticketvarianten")

		div(class="row flex box-row")
			div(class="col l6 m12 s12")
				div(class="diybox")
					div(class="diybox-content with-after")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--10.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h2(class="uppercase") Hardtickets
				div(class="transparentbox")
					ul(class="bullets")
						li Fertige Hardtickets mit System verknüpfen.
						li Tickets selbst gestalten und mit beliebigem Drucker selbst drucken.
						li Datenexport für Ticketerstellung und -versand durch externe Dienstleister.
			div(class="col l6 m12 s12")
				div(class="diybox")
					div(class="diybox-content with-after")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--11.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h2(class="uppercase") Print@Home
				div(class="transparentbox")
					ul(class="bullets")
						li Ticketdesign selbst erstellen.
						li Automatischer Versand der Tickets bei Zahlungseingang.

		div(class="row flex box-row")
			div(class="col l4 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--12.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h4 Ticketdesign
						div(class="row row-no-mb")
							div(class="col m12 s12")
								p Tickets individuell und frei gestaltbar.
			div(class="col l4 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--13.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h4 Personalisierung
						div(class="row row-no-mb")
							div(class="col m12 s12")
								p Bei Bedarf können persönliche Daten der Ticketkäufer im Kaufprozess automatisch erfasst und verarbeitet werden.
			div(class="col l4 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--14.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h4 Einlasskontrolle
						div(class="row row-no-mb")
							div(class="col m12 s12")
								p Dagobert bietet eine Android App für die Einlasskontrolle deiner Veranstaltung. Sowohl On- und Offlinebetrieb sind möglich. Verbindungsausfälle während der Veranstaltung stellen kein Problem dar.

		div(class="row")
			div(class="col s12")
				separator(text="Zahlungsmodalitäten")

		div(class="row flex box-row")
			div(class="col l6 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--16.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h2(class="uppercase with-after") Vorkasse
								p Fin-TS fähiges Bankkonto vorausgesetzt
			div(class="col l6 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--17.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h2(class="uppercase with-after") Kreditkarte
								p Es fallen Gebühren für den Zahlungsdienstleister an

		div(class="row flex box-row")
			div(class="col l6 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--15.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h2(class="uppercase with-after") Paypal
								p Es fallen Gebühren für den Zahlungsdienstleister an
			div(class="col l6 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--19.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h2(class="uppercase with-after") Überweisung
								p Es fallen Gebühren für den Zahlungsdienstleister an

		div(class="row")
			div(class="col s12")
				separator(text="Planungsfeatures")

		div(class="row box-row")
			div(class="col m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--Saalplan.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h2(class="uppercase with-after") Saalpläne
								p 0,25€ / gebuchtem Sitzplatz<br/>Saalplaneditor inkl. Social Distancing Funktionen inklusive.

		div(class="row flex box-row")
			div(class="col l6 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--20.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h2(class="uppercase with-after") Event-Planer
								p Automatisierter Vorverkauf für wiederkehrende Veranstaltungen
			div(class="col l6 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--21.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h2(class="uppercase with-after") Tour-Planer
								p Mit wenigen Klicks mehrere Spieltermine einer Tournee-Anlegen.

		div(class="row")
			div(class="col s12")
				separator(text="Marketingfeatures")

		div(class="row flex box-row")
			div(class="col l6 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--23.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h2(class="uppercase with-after") Newsletter
								p Zielgruppen aus Ticketverkäufen aufbauen und gezielt erneut per E-Mail ansprechen.
			div(class="col l6 m12 s12")
				div(class="diybox s-mb")
					div(class="diybox-content")
						div(class="row row-no-mb")
							div(class="col s12 diy-icon-container")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--22.png')" class="diy-icon")
						div(class="row row-no-mb")
							div(class="col m12 s12")
								h2(class="uppercase with-after") Affiliate-Modul
								p Lass deine Fans für dich Tickets verkaufen.<br/><br/>Erstelle Belohnungen die ab einer gewissen Menge verkaufter Tickets oder einem erzielten Umsatz an deine Unterstützer ausgegeben werden.

		div(class="row")
			div(class="col s12")
				separator(text="Dagobert nutzen")

		div(class="row")
			div(class="col s12")
				form-block(teaser="Hast du Interesse an Dagobert oder Fragen an uns? Lass von dir hören!" source="DIY Ticketing Seite" v-bind:form_config="formConfig" submit_label="Abschicken" endpoint="https://website-backend.dagorbert-apps.de/mails/relay" form_submitted_message="Vielen Dank!<br/>Wir melden uns so schnell wie möglich!")

</template>

<style scoped>
.diybox {
	background-color: #e5e5e5;
	color: #233642;
	padding: 3em;
	font-size: 1.2em;
	width: 100%;
}

.diybox .diybox-content {
	text-align: center;
	width: 100;
}

.diy-icon-container .diy-icon {
	width: auto;
	height: auto;
	max-width: 120px;
	margin: 0px auto;
}

.diybox .diybox-content h2,
.diybox .diybox-content h4 {
	font-weight: bold;
	margin: 2.3733333333rem auto 1.424rem auto;
}

.diybox .diybox-content p {
	width: 100%;
}

.diybox .diybox-content h2.uppercase {
	text-transform: uppercase;
}

.diybox .diybox-content h4 {
	text-transform: uppercase;
	font-size: 1.3em;
}

.diybox .diybox-content h2.with-after:after,
.diybox .diybox-content.with-after:after {
	content: '';
	width: 60px;
	height: 5px;
	background: #233642;
	display: block;
	margin: 10px auto;
}

.transparentbox {
	background-color: rgba(255, 255, 255, 0.7);
	color: #233642;
	padding: 2em;
	font-size: 1.2em;
	width: 100%;
}

@media screen and (max-width: 1200px) {
	.diybox .diybox-content h2 {
		font-size: 2em;
	}

	.diybox .diybox-content h4 {
		font-size: 1em;
	}
}

@media screen and (max-width: 440px) {
	.diybox .diybox-content h2 {
		font-size: 1.5em;
	}
}

</style>

<script>
import UrlMixin from '@/mixins/UrlMixin';

import Simplebox from '@/components/Simplebox';
import Separator from '@/components/Separator';
import Form from '@/components/Form';

export default {
	mixins: [ UrlMixin ],
	components: {
		'simplebox': Simplebox,
		'separator': Separator,
		'form-block': Form
	},
	data: function() {
		return {
			'formConfig': [
				{
					'label': 'Unternehmen',
					'key': 'company',
					'type': 'text',
					'required': true
				},
				{
					'label': 'Name',
					'key': 'contact_name',
					'type': 'text',
					'required': true
				},
				{
					'label': 'E-Mail Adresse',
					'key': 'contact_email',
					'type': 'email',
					'required': true
				},
				{
					'label': 'Telefonnummer',
					'key': 'phone',
					'type': 'text',
					'required': true
				},
				{
					'label': 'Erwartetes Ticketvolumen pro Jahr',
					'key': 'volume',
					'type': 'number',
					'required': true
				}
			]
		}
	}
}
</script>